@font-face {
    font-family: "icons";
    src: url("./icons.eot?0e6c9b3fa822bddf4889231b632caff3#iefix") format("embedded-opentype"),
url("./icons.woff2?0e6c9b3fa822bddf4889231b632caff3") format("woff2"),
url("./icons.woff?0e6c9b3fa822bddf4889231b632caff3") format("woff");
}

i[class^="icon-"]:before, i[class*=" icon-"]:before {
    font-family: icons !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-action:before {
    content: "\f101";
}
.icon-add_circle:before {
    content: "\f102";
}
.icon-add:before {
    content: "\f103";
}
.icon-alert:before {
    content: "\f104";
}
.icon-arrange:before {
    content: "\f105";
}
.icon-arrow_down:before {
    content: "\f106";
}
.icon-arrow_up:before {
    content: "\f107";
}
.icon-back_arrow:before {
    content: "\f108";
}
.icon-camera:before {
    content: "\f109";
}
.icon-close:before {
    content: "\f10a";
}
.icon-collab:before {
    content: "\f10b";
}
.icon-copy:before {
    content: "\f10c";
}
.icon-dashboard:before {
    content: "\f10d";
}
.icon-delete:before {
    content: "\f10e";
}
.icon-download:before {
    content: "\f10f";
}
.icon-drag:before {
    content: "\f110";
}
.icon-dropdown_caret:before {
    content: "\f111";
}
.icon-edit:before {
    content: "\f112";
}
.icon-email:before {
    content: "\f113";
}
.icon-error:before {
    content: "\f114";
}
.icon-events:before {
    content: "\f115";
}
.icon-eye:before {
    content: "\f116";
}
.icon-filter:before {
    content: "\f117";
}
.icon-front_arrow:before {
    content: "\f118";
}
.icon-home:before {
    content: "\f119";
}
.icon-journals:before {
    content: "\f11a";
}
.icon-learning:before {
    content: "\f11b";
}
.icon-lock_open:before {
    content: "\f11c";
}
.icon-lock:before {
    content: "\f11d";
}
.icon-logout:before {
    content: "\f11e";
}
.icon-loop:before {
    content: "\f11f";
}
.icon-mentor:before {
    content: "\f120";
}
.icon-notification:before {
    content: "\f121";
}
.icon-permission:before {
    content: "\f122";
}
.icon-phone:before {
    content: "\f123";
}
.icon-profile:before {
    content: "\f124";
}
.icon-project:before {
    content: "\f125";
}
.icon-right_arrow:before {
    content: "\f126";
}
.icon-search:before {
    content: "\f127";
}
.icon-share:before {
    content: "\f128";
}
.icon-study:before {
    content: "\f129";
}
.icon-subscription:before {
    content: "\f12a";
}
.icon-timer:before {
    content: "\f12b";
}
.icon-topics:before {
    content: "\f12c";
}
.icon-upload:before {
    content: "\f12d";
}
.icon-sort_left:before {
    content: "\f118";
}
.icon-more:before {
    content: "\22EE";
}
.icon-question:before {
    content: "\003F";
}
.icon-rightalign:before {
    content: "\251C";
}