.question-height > .field-items {
    min-width: 20% !important;
    max-width: 100% !important;
}

.questions-container{
  overflow: scroll !important;
}

.survey-form .questions-container .question-height .field-items .custom-radio, .survey-form .questions-container .question-height .field-items .custom-checkbox {
  justify-content: flex-start;
}

.survey-form .questions-container .button-bottom {
  width: auto !important;
  position: static !important;
  bottom: 40px;
}

.custom-radio > .align-center{
  display: flex;
}

.custom-checkbox > .align-center{
  display: flex;
  max-width: none;
}

.field-items .form-group .custom-file-upload .upload-container {
  height: auto;
  width: auto;
  border: 1px solid #ECEBED;
  border-radius: 4px;
  padding: 8px 1px;
}

.field-items > .form-group > .custom-radio {
  justify-content: flex-start !important;
}

.survey-form .questions-container .question-height {
  height: auto !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.button-bottom{
  margin-bottom: 10px !important;
}

.survey-form > .questions-container {
  height: calc(100vh - 42vh) !important;
  padding: 5px 15px 0;
  background-color: #FFFFFF;
  border-radius: 20px;
  position: relative;
}

.question-height > .field-name {
  /* color: #10173B; */
  line-height: 17px !important;
  /* margin-bottom: 32px !important; */
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) { 
    .survey-form {
      padding: 20px;
    }
    
    .welcome-text {
      font-size: 24px;
    }
    
    .survey-title {
      font-size: 36px;
    }
    
    .survey-description {
      font-size: 18px;
    }
    
    .survey-tool {
      width: 100%;
      height: auto;
    }
    
    .questions-container {
      padding: 20px;
    }
    
 
  }

  .check-item{
    display: flex !important;
    flex-direction: row !important;
  }
  
  